<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <h2 class="title-style">
      <v-icon
        class="icon-style"
        left
      >
        mdi-clipboard-text-clock-outline
      </v-icon> Time Sheets
    </h2>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <br>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            lg="4"
            md="5"
            sm="12"
          >
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :search="search"
        :items="instanceTimeSheets"
        disable-sort
        :footer-props="{
          'items-per-page-options': [10, 20, 30]
        }"
        :loading="updateProgress"
        :server-items-length="totalRecords"
        class="elevation-1"
        mobile-breakpoint="100"
        light
        @pagination="getRecordsFromServer"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>
              {{ item.start_date | convertToLocal }}
            </td>
            <td>
              {{ item.input_quantity }}  <span class="hrs-style">Hrs</span>
            </td>
            <td>
              {{ item.output_quantity }}  <span class="hrs-style">{{ item.output_unit }}</span>
            </td>
            <td v-if="isInstanceFromPartner">
              <font
                v-if="item.status !== null"
                class="status-style"
                :color="getStatusColor(item.status)"
              >
                {{ item.status | statusFormat }}
              </font>
              <font
                v-else
                class="status-style"
              >
                N/A
              </font>
            </td>
            <td>
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                @click="editTimeSheetData(item)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </td>
            <td v-if="isInstanceFromPartner">
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                @click.stop
                @click="routeToTicketChat(item.support_ticket)"
              >
                <v-icon>mdi-chat-plus</v-icon>
              </v-btn>
            </td>
            <td v-if="isInstanceFromPartner">
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                @click.stop
                @click="completeValidations(item.status, item.id)"
              >
                <v-icon>mdi-sync</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <br>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
    <edit-time-sheet-details
      v-if="showTimeSheetUpdateForm"
      :time-sheet-details="timeSheetDetails"
      @success="updateTimeSheetsPage"
      @closed="showTimeSheetUpdateForm = false"
    />
    <partner-auth-check
      ref="partnerAuth"
      @partner-authorized="retryTimesheetUpload"
    />
    <overlay-alert
      :show-progress="showProgress"
      :process-alert="processAlert"
    />
  </v-container>
</template>

<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import moment from 'moment';
  import EditTimeSheetDetails from './EditTimeSheetDetails';
  import Constants from 'src/constants';
  import PartnerAuthCheck from 'src/views/dashboard/partnerauth/PartnerAuthCheck';
  import OverlayAlert from 'src/views/dashboard/component/OverlayAlert';

  export default {
    name: 'InstanceTimeSheets',
    components: {
      'centre-spinner': spinner,
      'edit-time-sheet-details': EditTimeSheetDetails,
      'partner-auth-check': PartnerAuthCheck,
      'overlay-alert': OverlayAlert,
    },
    filters: {
      convertToLocal (date) {
        return moment(date).local().format('Do MMMM YYYY');
      },
      statusFormat (status) {
        if (status === 'successful') {
          return 'Uploaded*';
        } else if (status === 'fixed') {
          return 'Retry*';
        } else {
          return 'Failed';
        }
      },
    },
    mixins: [global],
    data () {
      return {
        loading: false,
        headers: [
          { text: 'Logs', align: 'start', value: 'name' },
          { text: 'Tracked Input Qty', value: 'input_quantity' },
          { text: 'Tracked Output Qty', value: 'output_quantity' },
          { text: 'Edit', value: 'edit' },
        ],
        search: '',
        timeSheetDetails: {},
        showTimeSheetUpdateForm: false,
        showProgress: false,
        processAlert: '',
        timeSheetId: '',
        updateProgress: false,
      };
    },
    computed: {
      trackingItemInstanceDetails () {
        return this.$store.getters['projects/getTrackingItemInstanceDetails'];
      },
      isInstanceFromPartner () {
        if (this.trackingItemInstanceDetails.database === false) {
          return true;
        }
        return false;
      },
      paginatedTimeSheetsStructure () {
        return this.$store.getters['projects/getInstanceTimeSheets'];
      },
      instanceTimeSheets () {
        const records = this.paginatedTimeSheetsStructure;
        return records.data;
      },
      totalRecords () {
        if (Object.keys(this.paginatedTimeSheetsStructure).length > 0) {
          return this.paginatedTimeSheetsStructure.total;
        }
        return 0;
      },
    },
    watch: {
      isInstanceFromPartner (val) {
        this.showUploadStatusColumn();
      },
    },
    created () {
      this.showUploadStatusColumn();
    },
    async mounted () {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
    methods: {
      async getInstanceTimeSheets () {
        this.loading = true;
          await this.$store.dispatch('projects/fetchInstanceTimeSheets', {
            trackingItemInstanceId: this.$route.params.trackingItemInstanceId,
          }).catch(() => {
            this.loading = false;
          });
        this.loading = false;
      },
      async getRecordsFromServer ({ itemsPerPage, pageStart }) {
        this.updateProgress = true;
        await this.$store.dispatch('projects/fetchInstanceTimeSheets', {
          trackingItemInstanceId: this.$route.params.trackingItemInstanceId,
          params: {
            params: {
              limit: itemsPerPage,
              startPage: pageStart,
            },
          },
        }).catch(() => {
          this.updateProgress = false;
        });
        this.updateProgress = false;
      },
      async getTrackingItemInstanceDetails () {
        this.loading = true;
        await this.$store.dispatch('projects/fetchTrackingItemInstanceDetails', {
          trackingItemInstanceId: this.$route.params.trackingItemInstanceId,
        });
        this.loading = false;
      },
      editTimeSheetData (details) {
        this.timeSheetDetails = details;
        this.showTimeSheetUpdateForm = true;
      },
      showUploadStatusColumn () {
        if (this.isInstanceFromPartner === true) {
          this.headers.splice(3, 0, { text: 'Status', value: 'status' });
          this.headers.splice(5, 0, { text: 'Chat', value: 'chat' });
          this.headers.splice(6, 0, { text: 'Retry', value: 'retry' });
        }
      },
      getStatusColor (status) {
        if (status === 'failed') {
          return '#C62828';
        } else if (status === 'successful') {
          return '#2E7D32';
        } else {
          return '#FF3700';
        }
      },
      updateTimeSheetsPage () {
        this.getRecordsFromServer({ itemsPerPage: 10, pageStart: 0 });
        this.getTrackingItemInstanceDetails();
      },
      routeToTicketChat (supportTicket) {
        if (supportTicket === undefined || supportTicket === null) {
          this.$store.dispatch('alert/onAlert', {
            message: 'No tickets were generated against this time sheet.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.$router.push(`/support/tickets/${supportTicket.id}/chats`);
      },
      completeValidations (uploadStatus, timeSheetId) {
        this.timeSheetId = timeSheetId;
        if (uploadStatus === 'successful') {
          this.$store.dispatch('alert/onAlert', {
            message: 'This time sheet has already been uploaded.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        if (uploadStatus === 'failed') {
          this.$store.dispatch('alert/onAlert', {
            message: 'A support ticket has been generated; please click the chat icon for more information about the issue.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        if (this.isInstanceFromPartner === true) {
          this.processAlert = 'Verifying...';
          this.showProgress = true;
          this.$refs.partnerAuth.isPartnerAuthorized();
          return;
        }
        this.retryTimesheetUpload();
      },
      async retryTimesheetUpload () {
        this.showProgress = false;
        this.loading = true;
        await this.$store.dispatch('projects/retryTimeSheetUpload', {
          timeSheetId: this.timeSheetId,
        }).then((response) => {
          this.loading = false;
          this.$store.dispatch('alert/onAlert', {
            message: 'Upload is in progress; you will be notified once it is finished.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.updateTimeSheetsPage();
        }).catch(() => {
          this.loading = false;
        });
      },
    },
  };
</script>

<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.v-data-table { background-color: #ECEFF1; }
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.font-my-style {
  text-transform: capitalize;
  font-weight: bold;
}
.currency-style{
  color: #FF3700;
  font-family: 'Times New Roman', Times, serif;
  font-size: 11px;
}
.hrs-style {
  color: #37474F;
  font-family: 'Times New Roman', Times, serif;
  font-size: 11px;
  text-transform: capitalize;
}
.sync-btn-txt {
  color: #FF3700;
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
  text-transform: capitalize;
  margin-top: 3px;
  margin-left: 5px;
  font-size: 15px;
}
.sync-btn {
  margin-left: 8px;
  margin-top: 10px;
}
.info-text {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  font-style: italic;
  color: #37474F;
  margin-left: 15px;
  margin-top: -20px;
}
.status-style{
  font-weight: bold;
  text-transform: capitalize;
}
</style>
