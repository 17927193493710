<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-menu
      v-if="isMobile"
      offset-x
      right
    >
      <template #activator="{ on, attrs }">
        <v-icon
          dark
          large
          color="#37474F"
          v-bind="attrs"
          v-on="on"
        >
          mdi-menu
        </v-icon>
      </template>
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="selectedItem"
          color="#37474F"
          mandatory
        >
          <v-list-item
            link
            @click="getInstanceTimeSheetsLogs()"
          >
            <v-list-item-icon>
              <v-icon class="drawer-style">
                mdi-clipboard-text-clock-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="drawer-item-style">
              Time Sheets
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <v-btn
      color="red darken-3"
      class="ml-7 my-back-btn-style"
      text
      x-large
      dark
      @click="back()"
    >
      <v-icon
        left
        dark
      >
        mdi-arrow-left-bold
      </v-icon>
      Back
    </v-btn>
    <div v-if="showTrackingInstanceDetails">
      <p class="project-header">
        Project Name:- <font
          class="project-name"
        >
          {{ trackingItemInstanceDetails.performance_tracking_item.project.name }}
        </font>
      </p>
      <v-row
        class="cost-items"
      >
        <v-col
          cols="12"
          md="4"
        >
          <v-card
            class="budget-card"
            color="ECEFF1"
            shaped
            hover
          >
            <v-card-title />
            <p class="budget-content">
              Instance Name:- <font
                class="font-my-style"
              >
                {{ trackingItemInstanceDetails.name }}
              </font>
            </p>
            <p class="budget-content">
              Input Quantity:- <font class="font-my-style">
                {{ trackingItemInstanceDetails.input_quantity }}<span class="unit-style"> Hrs</span>
              </font>
            </p>
            <p class="budget-content">
              Output Quantity:- <font
                class="font-my-style"
              >
                {{ trackingItemInstanceDetails.output_quantity }} <span class="unit-style">{{ trackingItemInstanceDetails.output_unit }}</span>
              </font>
            </p>
            <v-tooltip
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <p
                  class="budget-content"
                  v-bind="attrs"
                  v-on="on"
                >
                  Productivity Rate:- <font class="font-my-style">
                    {{ trackingItemInstanceDetails.productivity_rate | formatRate }} <span class="unit-style">Hrs/Unit</span>
                  </font>
                </p>
              </template>
              <span><div class="calc-header">Productivity Rate (Hours Per Unit)<hr></div>Input Quantity / <br>Output Quantity</span>
            </v-tooltip>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-card
            class="budget-date-card"
            color="ECEFF1"
            shaped
            hover
          >
            <v-card-title />
            <p class="budget-content">
              Tracked Input Quantity:- <font
                v-if="trackingItemInstanceDetails.tracked_input_quantity !== null"
                class="font-my-style"
              >
                {{ trackingItemInstanceDetails.tracked_input_quantity }}<span class="unit-style"> Hrs</span>
              </font>
              <font
                v-else
                class="font-my-style"
              >
                N/A
              </font>
            </p>
            <p class="budget-content">
              Tracked Output Quantity:- <font
                v-if="trackingItemInstanceDetails.tracked_output_quantity !== null"
                class="font-my-style"
              >
                {{ trackingItemInstanceDetails.tracked_output_quantity }}<span class="unit-style"> {{ trackingItemInstanceDetails.output_unit }}</span>
              </font>
              <font
                v-else
                class="font-my-style"
              >
                N/A
              </font>
            </p>
            <v-tooltip
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <p
                  class="budget-content"
                  v-bind="attrs"
                  v-on="on"
                >
                  Tracked Productivity Rate:- <font
                    v-if="trackingItemInstanceDetails.tracked_productivity !== null"
                    class="font-my-style"
                  >
                    {{ trackingItemInstanceDetails.tracked_productivity | formatRate }} <span class="unit-style">Hrs/Unit</span>
                  </font>
                  <font
                    v-else
                    class="font-my-style"
                  >
                    N/A
                  </font>
                </p>
              </template>
              <span> <div class="calc-header">Tracked Productivity Rate (Hours Per Unit)<hr></div>Tracked Input Quantity / <br>Tracked Output Quantity</span>
            </v-tooltip>
            <v-tooltip
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <p
                  class="budget-content"
                  v-bind="attrs"
                  v-on="on"
                >
                  Performance Ratio:- <font
                    :color="getPerformanceColor(trackingItemInstanceDetails.performance_ratio)"
                  >
                    {{ trackingItemInstanceDetails.performance_ratio | formatRate }}
                  </font>
                </p>
              </template>
              <span> <div class="calc-header">Performance Ratio (Hours Per Unit)<hr></div> Productivity Rate (Hours Per Unit) / <br>Tracked Productivity Rate (Hours Per Unit)</span>
            </v-tooltip>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-card
            class="budget-cost-card"
            color="ECEFF1"
            shaped
            hover
          >
            <v-card-title />
            <v-tooltip
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <p
                  class="budget-content"
                  v-bind="attrs"
                  v-on="on"
                >
                  Input Used:- <font
                    class="font-my-style"
                  >
                    {{ trackingItemInstanceDetails.input_used | formatToPercentage }}<span class="unit-style"> % </span>
                  </font>
                </p>
              </template>
              <span><div class="calc-header">Input Used (%)<hr></div>Tracked Input Quantity / <br>Input Quantity</span>
            </v-tooltip>
            <v-tooltip
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <p
                  class="budget-content"
                  v-bind="attrs"
                  v-on="on"
                >
                  Output Used:- <font
                    class="font-my-style"
                  >
                    {{ trackingItemInstanceDetails.output_completed | formatToPercentage }} <span class="unit-style"> %</span>
                  </font>
                </p>
              </template>
              <span><div class="calc-header">Output Used (%)<hr></div>Tracked Output Quantity / <br>Output Quantity</span>
            </v-tooltip>
            <v-tooltip
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <p
                  class="budget-content"
                  v-bind="attrs"
                  v-on="on"
                >
                  Remaining Input: <font
                    :color="getDifferenceColor(trackingItemInstanceDetails.remaining_planned_input)"
                    class="difference-font"
                  >
                    {{ trackingItemInstanceDetails.remaining_planned_input }}<span class="unit-style"> Hrs </span>
                  </font>
                </p>
              </template>
              <span><div class="calc-header">Remaining Input Quantity<hr></div>Input Quantity - <br>Tracked Input Quantity</span>
            </v-tooltip>
            <v-tooltip
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <p
                  class="budget-content"
                  v-bind="attrs"
                  v-on="on"
                >
                  Remaining Output:- <font
                    :color="getDifferenceColor(trackingItemInstanceDetails.remaining_output_quantity)"
                    class="difference-font"
                  >
                    {{ trackingItemInstanceDetails.remaining_output_quantity }}<span class="unit-style"> {{ trackingItemInstanceDetails.output_unit }}</span>
                  </font>
                </p>
              </template>
              <span><div class="calc-header">Remaining Output Quantity<hr></div>Output Quantity - <br>Tracked Output Quantity</span>
            </v-tooltip>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <br>
    <hr>
    <br>
    <v-navigation-drawer
      v-if="!isMobile"
      permanent
      color="#ECEFF1"
      expand-on-hover
      absolute
      bottom
      floating
    >
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="nav-title-style">
              <v-list-item-icon>
                <v-icon
                  class="drawer-style"
                >
                  mdi-checkbox-multiple-blank-outline
                </v-icon>
              </v-list-item-icon>
              Details
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <br>
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="selectedItem"
          color="#37474F"
          mandatory
        >
          <v-list-item
            link
            @click="getInstanceTimeSheetsLogs()"
          >
            <v-list-item-icon>
              <v-icon class="drawer-style">
                mdi-clipboard-text-clock-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="drawer-item-style">
              Time Sheets
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <centre-spinner
      :loading="loading"
    />
    <instance-time-sheets v-if="showInstanceTimeSheets" />
  </v-container>
</template>

<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import InstanceTimeSheets from './InstanceTimeSheets';

  export default {
    name: 'InstanceSecondNavDrawer',
    components: {
      'centre-spinner': spinner,
      'instance-time-sheets': InstanceTimeSheets,
    },
    filters: {
      formatRate (val) {
        return Number(val).toFixed(2);
      },
      formatToPercentage (val) {
        return val * 100;
      },
    },
    data: () => ({
      selectedItem: -1,
      showInstanceTimeSheets: true,
      loading: false,
    }),
    computed: {
      isMobile () {
        if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
          return false;
        } else {
          return true;
        }
      },
      trackingItemInstanceDetails () {
        return this.$store.getters['projects/getTrackingItemInstanceDetails'];
      },
      showTrackingInstanceDetails () {
        if (Object.keys(this.trackingItemInstanceDetails).length > 0) {
          return true;
        }
        return false;
      },
    },
    async mounted () {
      this.selectedItem = 0;
      await this.getTrackingItemInstanceDetails();
    },
    methods: {
      async getTrackingItemInstanceDetails () {
        this.loading = true;
        await this.$store.dispatch('projects/fetchTrackingItemInstanceDetails', {
          trackingItemInstanceId: this.$route.params.trackingItemInstanceId,
        });
        this.loading = false;
      },
      back () {
        const projectId = this.$route.params.projectId;
        const trackingItemId = this.$route.params.trackingItemId;
        this.$router.push(`/projects/${projectId}/performance-tracking-items/${trackingItemId}/instances`);
      },
      getDifferenceColor (val) {
        if (val > 0) {
          return '#2E7D32';
        }
        return '#C62828';
      },
      getPerformanceColor (val) {
        if (val >= 1) {
          return '#2E7D32';
        } else {
          return '#C62828';
        }
      },
      getInstanceTimeSheetsLogs () {
        this.showInstanceTimeSheets = true;
      },
    },
  };
</script>
<style scoped>
.nav-title-style {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  color: #37474F;
  font-size: 25px;
}
.my-back-btn-style {
  margin-bottom: -20px;
}
@media (max-width: 959px) {
  .my-back-btn-style {
    margin-bottom: 0px;
    margin-left: 0px !important;
  }
  .budget-card {
    margin-left: -20px;
  }
  .budget-date-card {
    margin-top: -20px;
    margin-left: -20px;
  }
  .budget-cost-card {
    margin-top: -20px;
    margin-left: -20px;
  }
  .project-header {
    margin-left: 20px;
  }
}
@media (min-width: 959px) and (max-width: 1245px) {
  .font-my-style {
  font-weight: bold;
  font-size: 12px !important;
  color: #37474F;
  }
  .budget-content {
  font-size: 12px !important;
  font-weight: bold;
  color: #546E7A;
  margin-left: 12px !important;
  line-height: 0.8;
  }
  .difference-font {
    font-size: 12px !important;
  }
}
.drawer-style {
  font-size:25px;
  color:#37474F;
  font-weight:bold;
}
.drawer-item-style {
  font-size:14px !important;
  color:#37474F !important;
  font-weight:bold !important;
}
.type-style {
  text-transform: capitalize;
  font-weight: bold;
}
.my-style {
  margin-bottom: 10px !important;
}
.my-hrw-style {
  text-transform: capitalize;
  font-weight: bold;
}
.My-checkout-time {
  color:#2E7D32;
  font-weight: bold;
}
.status-font-style {
  font-family: 'Times New Roman', Times, serif;
  text-transform: capitalize;
  font-weight: bold;
}
.job-status-style {
  text-transform: capitalize;
  font-weight: bold;
}
.budget-content {
  font-size: 14px;
  font-weight: bold;
  color: #546E7A;
  margin-left: 15px;
  line-height: 0.8;
}
.font-my-style {
  font-weight: bold;
  font-size: 14px;
  color: #37474F;
}
.difference-font {
  font-weight: bold;
  font-size: 14px;
}
.budget-card {
  background: #ECEFF1;
  border-bottom: 2px solid #CFD8DC !important;
  border-top: 8px solid #CFD8DC !important;
  padding-top: 15px;
  cursor: default;
}
.budget-cost-card {
  background: #ECEFF1;
  border-bottom: 2px solid #CFD8DC !important;
  border-top: 8px solid #CFD8DC !important;
  padding-top: 15px;
  cursor: default;
}
.budget-date-card {
  background: #ECEFF1;
  border-bottom: 2px solid #CFD8DC !important;
  border-top: 8px solid #CFD8DC !important;
  padding-top: 15px;
  cursor: default;
}
.project-header {
  margin-left: 40px;
  margin-top: 25px;
  margin-bottom: -20px;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bolder;
  color: #37474F;
  font-size: 18px;
}
.project-name {
  color: #FF3700;
}
.cost-items {
  margin-left: 25px;
}
.currency-style {
  color: #FF3700;
  font-family: 'Times New Roman', Times, serif;
  font-size: 12px;
}
.unit-style {
  color: #37474F;
  font-family: 'Times New Roman', Times, serif;
  text-transform: capitalize;
}
.calc-header {
  text-align: center !important;
  margin-bottom: 15px;
}
</style>
